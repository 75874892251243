import React, { lazy } from "react";

const SignIn = lazy(() => import("./views/SignIn/login"));
const ForgotPassword = lazy(() => import("./views/SignIn/forgotPassword"));
const NewPassword = lazy(() => import("./views/SignIn/NewPassword"));
const LoginCard = lazy(() => import("./views/Home/LoginPageForSecure"));

const Authroutes = [
  {
    invisible: false,
    name: "signIn",
    link: "/signIn",
    Element: <SignIn />,
  },
  {
    invisible: false,
    link: "/forgetpassword",
    Element: <ForgotPassword />,
  },
  {
    invisible: false,
    link: "/newpassword",
    Element: <NewPassword />,
    name: "newpassword",
  },
  {
    link: "/api/login",
    Element: <LoginCard />,
    name: "apilogin",
  },
];

export default Authroutes;
