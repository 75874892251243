import { createContext, useContext, useReducer } from "react";
import PropTypes from "prop-types";

const Filter = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "FILTER_DATA":
      return { ...state, filterData: action.value };
      break;
    case "LATER_STATUS":
      return { ...state, laterStatus: action.value };
      break;
    default: {
      throw new Error(`action type : ${action.type}`);
    }
  }
};

function FilterControllerProvider({ children }) {
  const initialState = {
    filterData: {
      place: [],
      smartBillboard: [],
      venue: [],
      country: [],
      state: [],
      size: [],
      price: [],
      district: "",
    },
    laterStatus: false,
  };
  const [controller, dispatch] = useReducer(reducer, initialState);

  return (
    <Filter.Provider value={[controller, dispatch]}>{children}</Filter.Provider>
  );
}
FilterControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useFilterController = () => {
  const context = useContext(Filter);
  if (!context) {
    throw new Error(
      "useFilterController should be used inside the FilterControllerProvider."
    );
  }
  return context;
};

const setFilterData = (dispatch, value) =>
  dispatch({ type: "FILTER_DATA", value });
const setLaterStatus = (dispatch, value) =>
  dispatch({ type: "LATER_STATUS", value });

export {
  FilterControllerProvider,
  useFilterController,
  setFilterData,
  setLaterStatus,
};
