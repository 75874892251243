import React, { Suspense } from "react";
import { Container, CircularProgress } from "@mui/material";
import { Routes, Route, Navigate } from "react-router-dom";
import PermissionUtils from "../component/permissionUtils";

const LoadingIndicator = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "80vh",
      width: "100%",
      padding: "20px",
    }}
  >
    <CircularProgress color="primary" />
  </div>
);

export default function Admin() {
  const [updatedRoutesPermit] = PermissionUtils();
  return (
    <>
      <div
        style={{
          marginTop: "100px",
          marginLeft: "72px",
          backgroundColor: "#ffffff",
        }}
      >
        <Container maxWidth="xl">
          <Suspense fallback={<LoadingIndicator />}>
            <Routes>
              {updatedRoutesPermit &&
                updatedRoutesPermit?.map((prop) => (
                  <Route
                    exact
                    path={prop?.link}
                    element={prop?.Element}
                    key={prop?.key}
                  />
                ))}
              <Route
                path="*"
                element={<Navigate replace={true} to="/admin/home" />}
              />
            </Routes>
          </Suspense>
        </Container>
      </div>
    </>
  );
}
