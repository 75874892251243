import { Typography, Grid } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../api/axiosInstance";
import LoaderDialogue from "../LoaderDialogue/LoaderDialogue";
import notification from "../../assets/Img/notification.gif";
import moment from "moment";
const Notifications = ({ data, closeNotoHandler, getNotifications }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);
  const notificationsRef = useRef();
  useEffect(() => {
    // Add event listener to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (
        notificationsRef.current &&
        !notificationsRef.current.contains(event.target)
      ) {
        // Clicked outside the component
        setIsVisible(false);
        // Navigate to the next page
      }
    };

    // Attach the event listener to the document
    document.addEventListener("click", handleClickOutside);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [navigate]);

  const changeReadStatus = async (notification) => {
    console.log(notification, "notification");
    getNotifications();

    try {
      const res = await axiosInstance.post(
        "/api/notification/changeStatusToRead",
        {
          notificationId: notification._id,
        }
      );

      if (notification?.messageType === "Burner Ad") {
        console.log("Inside Burner Ad condition");
        if (res) {
          navigate(`/admin/billboardscreens/${notification?.detailPage}`);
          setIsVisible(false);
        }
      } else {
        if (notification?.messageType === "Withdraw") {
          navigate(`/admin/finance?tab=4`);
          getNotifications();
          setIsVisible(false);
        } else {
          navigate(`/admin/order/${notification?.detailPage}`, {
            state: { notification },
          });
          getNotifications();
          setIsVisible(false);
        }
      }
    } catch (error) {
      console.error("Error in changeReadStatus:", error.message);
    }
  };
  const SeeAllHandle = () => {
    navigate("/admin/notification");
    closeNotoHandler();
  };

  function formatDate(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear() +
      " , " +
      strTime
    );
  }

  return (
    <div
      ref={notificationsRef}
      style={{ width: "500px", display: isVisible ? "block" : "none" }}
      className="notification_abslute_div"
    >
      <Grid container>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          sx={{
            padding: "10px",
            background: "#fff",
            borderBottom: "1px solid #ddd",
            // boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.1)",
            boxShadow: "0px 16px 48px 0px rgba(0, 0, 0, 0.176) !important",
          }}
        >
          <Typography className="blackcolor484848 fs16px">
            Notifications
          </Typography>
          <button
            className="notification_btn blackcolor484848 fs16px"
            onClick={() => SeeAllHandle()}
          >
            See all
          </button>
        </Grid>
        <Grid
          container
          xs={12}
          className="notification_scroll_div"
          sx={{
            // height: "400px",
            background: "#f7f8fd",
            color: "black",
          }}
          direction="column"
          gap="5px"
        >
          {data && data.length > 0 ? (
            data?.slice(0, 5)?.map((a, i) => (
              <Grid
                container
                key={i}
                item
                onClick={() => {
                  changeReadStatus(a);
                }}
                sx={{
                  cursor: "pointer",

                  padding: "10px",
                  borderBottom: "1px solid #ddd",
                }}
              >
                <Grid
                  container
                  item
                  xs={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <img
                    src={a?.image}
                    style={{
                      objectFit: "contain",
                    }}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={10}
                  direction="column"
                  justifyContent="center"
                  gap="3px"
                >
                  <Grid item>
                    <Typography className="blackcolor484848 fs16px">
                      {a?.message}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className="greyColorTypo717171_500 fs14px">
                      {a?.orderId}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className="greyColorTypo717171_500 fs14px">
                      {/* {moment(a?.createdAt)?.format("lll")} */}
                      {formatDate(new Date(a?.createdAt))}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%", height: "100%" }}
              direction="column"
            >
              <Grid container justifyContent="center">
                {" "}
                <img
                  src={notification}
                  alt="notification"
                  style={{ width: "150px" }}
                />
              </Grid>

              <Typography className="fs20px">
                No notifications to show yet
              </Typography>
              <Typography className=" fs16px" sx={{ color: "#6D787D" }}>
                You’ll see useful information here soon. Stay tuned!
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <LoaderDialogue loading={loading} />
    </div>
  );
};

export default Notifications;
