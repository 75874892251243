import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import moment from "moment";
import {
  Box,
  Grid,
  Badge,
  Button,
  Tooltip,
  Toolbar,
  useTheme,
  Container,
  ListItem,
  IconButton,
  CssBaseline,
  ListItemText,
  ListItemIcon,
  useMediaQuery,
} from "@mui/material";
import Fade from "@mui/material/Fade";
//  ** React Icons and Images
import { GrClose } from "react-icons/gr";
import { RiCloseFill } from "react-icons/ri";
import { FaUpload } from "react-icons/fa";
import CampaignIcon from "@mui/icons-material/Campaign";
// Example icons
import { FaRegUserCircle } from "react-icons/fa";
import { AiOutlineLogout } from "react-icons/ai";
import ASAPlogo from "../../assets/Img/postmyaddsm.png";
import SAPSName from "../../assets/Img/Postmyadmd.png";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";

// ** Core Component
import { AuthContext } from "../../Context/AuthContext";
import axiosInstance from "../../api/axiosInstance";
import Notifications from "./Notifications";
import permissionUtils from "../permissionUtils";
import MenuProfile from "./Menu";
import { newSocket } from "../socket";
const drawerWidth = 240;
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const Dashboard = () => {
  // context api state
  const theme = useTheme();
  const navigate = useNavigate();
  const [updatedRoutesPermit, PermissionCheck] = permissionUtils();

  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openNoti, setOpenNoti] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const { user, setToken } = useContext(AuthContext);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [pendingLength, setPendingLength] = useState();
  const getContent = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/user/ads-gallery/getUserContent?page=${1}&limit=1&status=${1}`
      );
      if (res.status == 200) {
        setPendingLength(res?.data?.data);
      }
    } catch (err) {
      console.log("error while getting the pending content ", err);
    }
  };

  // Handle logout and other functions remain the same
  useEffect(() => {
    getNotifications();
    getContent();
  }, []);

  async function getNotifications() {
    try {
      let resp = await axiosInstance.get(
        `/api/notification/getallnotification?userId=${user._id}&status=Unread`
      );
      for (let item of resp.data.msg) {
        // let msg = `${item.message} with the orderId #${item.orderId}`;
        let now = moment();
        let value =
          now.diff(item.createdAt, "seconds") < 60 &&
          now.diff(item.createdAt, "minutes") < 1
            ? `${now.diff(item.createdAt, "seconds")} sec ago`
            : now.diff(item.createdAt, "minutes") > 1 &&
              now.diff(item.createdAt, "minutes") < 60
            ? `${now.diff(item.createdAt, "minutes")} min ago`
            : now.diff(item.createdAt, "minutes") > 60 &&
              now.diff(item.createdAt, "days") < 1
            ? `${now.diff(item.createdAt, "hours")} hr ${Math.round(
                now.diff(item.createdAt, "minutes") / 60
              )} min ago`
            : `${now.diff(item.createdAt, "seconds")} day ago`;
        // ((now.diff(item.createdAt, "days") > 1) && now.diff(item.createdAt, "minutes") > 60) ?
        // `${now.diff(item.createdAt, "seconds")} day ago` : ""
        item["receivedTime"] = value;
        // item["message"] = msg;
      }
      setData(resp.data.msg);
    } catch (error) {
      console.log("error from getNotifications ==>", error);
    }
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const logout = () => {
    setToken(null);
    localStorage.removeItem("userData");
    localStorage.removeItem("access_token");
    localStorage.removeItem("permission");
    navigate("/signIn");
    newSocket.disconnect();
  };

  // ===================== Get Permissions ==================== //

  const navigateHandle = (url) => {
    navigate(url);
    setOpen(false);
  };

  const closeNotoHandler = () => {
    setOpenNoti(false);
  };
  const homeLink = (
    <ListItem
      // component={Link}
      onClick={() => {
        navigate("/admin/home");
      }}
    >
      {open ? (
        <>
          <ListItemIcon sx={{ paddingLeft: "5px" }}>
            <img src={ASAPlogo} className="sidebar-logo" alt="ASAP Logo" />
          </ListItemIcon>
          <img src={SAPSName} className="drawerLogo" alt="SAPS Name" />
        </>
      ) : (
        <ListItemIcon sx={{ paddingLeft: "5px" }}>
          <img src={ASAPlogo} className="sidebar-logo" alt="ASAP Logo" />{" "}
        </ListItemIcon>
      )}
    </ListItem>
  );
  return (
    <>
      <CssBaseline />
      <Drawer variant="permanent" open={open} onClick={closeNotoHandler}>
        <Grid container mt={1}>
          {homeLink}
        </Grid>
        <Grid container>
          {open ? (
            <ListItem
              button
              // component={Link}
              className="ListItemStyle"
              onClick={toggleDrawer}
            >
              <ListItemIcon className="SideBarIcon purplecolortypo">
                <RiCloseFill className="greycolortypo" />
              </ListItemIcon>
              <ListItemText disableTypography className="greycolortypo">
                Close
              </ListItemText>
            </ListItem>
          ) : (
            <ListItem
              button
              // component={Link}
              className="ListItemStyle cursor"
              onClick={toggleDrawer}
            >
              <ListItemIcon className="SideBarIcon purplecolortypo">
                <MenuIcon className="purplecolortypo" />
              </ListItemIcon>
              <ListItemText disableTypography className="greycolortypo">
                Close
              </ListItemText>
            </ListItem>
          )}
        </Grid>

        {updatedRoutesPermit &&
          updatedRoutesPermit?.map((route) => {
            if (route?.invisible === false) {
              return (
                <Tooltip
                  title={!open ? route?.name : ""}
                  arrow
                  placement="right"
                  TransitionComponent={Fade}
                >
                  <ListItem
                    button
                    className="ListItemStyle"
                    component={Link}
                    to={route.link}
                    onClick={() => navigateHandle(route.link)}
                  >
                    <ListItemIcon className="SideBarIcon purplecolortypo">
                      {route.icon}
                      {route?.name === "Gallery" &&
                        pendingLength?.totalDocuments > 0 && (
                          <span className="pendingCountSiderbar">
                            {" "}
                            {pendingLength?.totalDocuments}{" "}
                          </span>
                        )}
                    </ListItemIcon>

                    <ListItemText disableTypography className="darkblacktypo">
                      {route.name}
                    </ListItemText>
                  </ListItem>
                </Tooltip>
              );
            }
          })}
        <ListItem
          className="ListItemStyle mb20px  cursor_pointer logoutHover"
          onClick={logout}
        >
          <ListItemIcon className="SideBarIcon purplecolortypo">
            <AiOutlineLogout onClick={logout} />
          </ListItemIcon>
          <ListItemText
            onClick={logout}
            disableTypography
            className="darkblacktypo"
          >
            Logout
          </ListItemText>
        </ListItem>
      </Drawer>
      {/* ** Navbar Custom */}
      <Box
        sx={{
          overflow: "auto",
        }}
      >
        <div className="drawerContent">
          <Grid container className="ml72px">
            <Container maxWidth="xl">
              <Grid
                container
                className="width-90LR nav-container"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <div align="center" className="width100">
                    <Link to="/admin/home" className="link-style">
                      <img src={SAPSName} className="drawerLogo" />
                    </Link>
                  </div>
                </Grid>
                <Grid item>
                  <div className="nav-container-child">
                    {/* {user?.userRole === 0 && (
                      <Button
                        onClick={() => navigate("/admin/apk-manager")}
                        variant="outlined"
                        className="upload-apk-button"
                      >
                        Upload TV Apk
                      </Button>
                    )} */}
                    <Grid container>
                      {user?.userRole === 0 && (
                        <>
                          <Grid
                            item
                            style={{ marginBottom: "10px" }}
                            sx={{
                              display: { xs: "none", sm: "block" },
                            }}
                          >
                            <Button
                              onClick={() => navigate("/admin/upload-tutorial")}
                              variant="outlined"
                              className="upload-apk-button"
                              fullWidth
                              startIcon={<FaUpload />}
                            >
                              <span className="textVisible">
                                Upload Tutorial
                              </span>
                            </Button>
                          </Grid>

                          <Tooltip title="Upload Tutorial">
                            <IconButton
                              size="medium"
                              onClick={() => navigate("/admin/upload-tutorial")}
                              sx={{
                                p: 0,
                                display: { xs: "block", sm: "none" },
                              }}
                            >
                              <FaUpload />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}

                      {PermissionCheck("create-campaign") && (
                        <>
                          <Grid
                            item
                            sx={{
                              marginLeft: "10px",
                              display: { xs: "none", sm: "block" },
                            }}
                          >
                            <Link
                              className="linkcolor"
                              to="/admin/createcampaign"
                            >
                              <Button
                                className="navbuttonstyle-2"
                                fullWidth
                                startIcon={<CampaignIcon />}
                              >
                                <span className="textVisible">
                                  Create Campaign
                                </span>
                              </Button>
                            </Link>
                          </Grid>

                          <Tooltip title="Create Campaign">
                            <IconButton
                              size="large"
                              onClick={() => navigate("/admin/createcampaign")}
                              sx={{
                                p: 0,
                                ml: 2,
                                mr: 1,
                                display: { xs: "block", sm: "none" },
                              }}
                            >
                              <CampaignIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </Grid>
                    <div className="relative mr16px">
                      <Badge
                        max={5}
                        badgeContent={data?.length}
                        className="filter_badge"
                      >
                        <NotificationsIcon
                          style={{
                            color: "rgba(0, 0, 0, 0.54)",
                            cursor: "pointer",
                          }}
                          onClick={() => setOpenNoti(!openNoti)}
                        />
                        {openNoti && (
                          <Notifications
                            data={data}
                            closeNotoHandler={closeNotoHandler}
                            getNotifications={getNotifications}
                          />
                        )}
                      </Badge>
                    </div>
                    <Tooltip title="Open settings">
                      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <FaRegUserCircle />
                      </IconButton>
                    </Tooltip>
                    <MenuProfile
                      logout={logout}
                      anchorElUser={anchorElUser}
                      handleCloseUserMenu={handleCloseUserMenu}
                    />
                  </div>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </div>
      </Box>
    </>
  );
};

export default Dashboard;
