import axios from "axios";
import { FETCH_URL } from "../fetchIp";

const axiosInstance = axios.create({
  baseURL: FETCH_URL,
});
const requestInterceptor = axiosInstance.interceptors.request.use(
  (request) => {
    const accessToken = localStorage.getItem("access_token");
    // const storeData = JSON.parse(localStorage.getItem('userData'))
    if (accessToken) {
      // console.log("access Token from interceptor ==> ", accessToken)
      request.headers["Authorization"] = "Bearer " + accessToken;
      request.headers["Content-Type"] = "application/json";
    }
    // Set Content-Type based on the data type
    if (request.data instanceof FormData) {
      // If the request contains FormData (file upload)
      request.headers["Content-Type"] = "multipart/form-data";
    } else {
      // For all other requests
      request.headers["Content-Type"] = "application/json";
    }

    return request;
  },
  (error) => {
    return Promise.reject(error); // Pass the error to be caught in the catch block
  }
);
const responseInterceptor = axiosInstance.interceptors.response.use(
  (response) => {
    // console.log("Response from Response Interceptor", response)
    return response;
  },
  async (error) => {
    // console.log("error from response Interceptor ==> ", error?.config)
    const originalRequest = error?.config;
    if (error?.response?.status == 401 && !originalRequest?.sent) {
      //401 unauthorized // invalid token / expired token
      // console.log("Refreshing...");
      localStorage.removeItem("loginToken");
      localStorage.removeItem("loginToken");
      localStorage.removeItem("access_token");
      localStorage.removeItem("userData");
      window.location.href = "/signIn";
      originalRequest.sent = true;
    }
    throw error;
  }
);

export default axiosInstance;
