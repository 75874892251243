import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Authroutes from "../Authroutes";

export default function Auth() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.link) {
        return (
          <Route exact path={prop.link} element={prop.Element} key={prop.key} />
        );
      } else {
        return null;
      }
    });
  };
  console.log("Working Auth Layout");

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {getRoutes(Authroutes)}
          <Route path="/" element={<Navigate replace={true} to="/signIn" />} />
        </Routes>
      </Suspense>
    </>
  );
}
