import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { Menu, MenuItem, Typography } from "@mui/material";

const Dashboard = ({ logout, anchorElUser, handleCloseUserMenu }) => {
  const navigate = useNavigate();

  const navigateHandle = (url) => {
    navigate(url);
    handleCloseUserMenu();
  };

  return (
    <>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem onClick={() => navigateHandle("/admin/userdashborad")}>
          <Typography textAlign="center" className="blackcolor484848">
            Profile
          </Typography>{" "}
        </MenuItem>{" "}
        <MenuItem onClick={() => navigateHandle("/admin/WishList")}>
          <Typography textAlign="center" className="blackcolor484848">
            My Wishlist
          </Typography>
        </MenuItem>{" "}
        <MenuItem onClick={logout}>
          <Typography textAlign="center" className="blackcolor484848">
            Logout
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Dashboard;
