import { useEffect, useState, useCallback, useMemo } from "react";
import routes from "../../routes";
import axiosInstance from "../../api/axiosInstance";
import { newSocket } from "../socket/index";

const PermissionUtils = () => {
  const [state, setState] = useState({
    userID: null,
    token: null,
    role: null,
    accessPermission: [],
    snackbarMessage: "",
    snackbarType: "success",
    snackbarOpen: false,
    userRoutes: [],
  });

  const {
    userID,
    role,
    accessPermission,
    snackbarMessage,
    snackbarType,
    snackbarOpen,
    userRoutes,
  } = state;

  const updateState = (newState) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  const openSnackbar = useCallback(
    (message, type) => {
      updateState({
        snackbarMessage: message,
        snackbarType: type,
        snackbarOpen: true,
      });

      setTimeout(() => {
        updateState({
          snackbarOpen: false,
        });
      }, 4000);
    },
    [updateState]
  );

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const access_token = localStorage.getItem("access_token");
    if (userData && userData.user && userData.user._id) {
      updateState({
        userID: userData.user._id,
        token: access_token,
        role: userData.user.userRole,
      });
    }
  }, []);

  // useEffect(() => {
  //   if (role !== null) {
  //     let updatedRoutes = [...routes];
  //     if (role === 2) {
  //       updatedRoutes = [...updatedRoutes, ...role2Routes];
  //     } else if (role === 0) {
  //       updatedRoutes = [...updatedRoutes, ...roleZeroRoutes];
  //     }
  //     updateState({ userRoutes: updatedRoutes });
  //   }
  // }, [role]);

  useEffect(() => {
    if (userID) {
      getPermission();
    }
  }, [userID]);

  const getPermission = useCallback(async () => {
    try {
      const resp = await axiosInstance.get(
        `/api/permission/getUserPermission/${userID}`
      );
      updateState({ accessPermission: resp?.data?.permissions || [] });
    } catch (err) {
      console.error("Error fetching permissions", err);
    }
  }, [userID]);

  useEffect(() => {
    try {
      newSocket.on("userPermissionUpdated", (data) => {
        updateState({ accessPermission: data?.data || [] });
      });
    } catch (error) {
      console.error("Error initializing socket:", error);
    }
  }, [newSocket]);

  const notAllowedPermissions = useMemo(
    () => accessPermission.filter((item) => item.allowed === false),
    [accessPermission]
  );

  const updatedRoutesPermit = useMemo(() => {
    return routes.filter(
      (route) =>
        !notAllowedPermissions.some(
          (permission) => permission.key === route.key
        )
    );
  }, [routes, notAllowedPermissions]);

  const PermissionCheck = useCallback(
    (key) => !notAllowedPermissions.some((ele) => ele.key === key),
    [notAllowedPermissions]
  );

  const CheckTabsPermissions = useCallback(
    (permissionList) => {
      const initialPermissions = {};
      permissionList.forEach((permission) => {
        initialPermissions[permission] = PermissionCheck(permission);
      });
      return initialPermissions;
    },
    [PermissionCheck]
  );

  const editAccessPermission = useCallback(
    async (userID, changePermission, setEdit) => {
      try {
        const body = { permissions: changePermission, userId: userID };
        const res = await axiosInstance.post(
          `api/permission/updateUserPermission`,
          body
        );
        if (res?.status === 200 || res?.status === 201) {
          setEdit(false);
          openSnackbar(
            res?.data?.msg || "Permission updated successfully.",
            "success"
          );
        } else {
          console.error("Unexpected response status", res?.status);
        }
      } catch (error) {
        openSnackbar(
          error?.response?.data?.msg || "An error occurred.",
          "error"
        );
      }
    },
    [openSnackbar]
  );

  return [
    updatedRoutesPermit,
    PermissionCheck,
    CheckTabsPermissions,
    accessPermission,
    snackbarType,
    snackbarOpen,
    snackbarMessage,
    updateState,
    getPermission,
    editAccessPermission,
  ];
};

export default PermissionUtils;
