import { createContext, useContext, useReducer } from "react";
import PropTypes from "prop-types";

const SelectBillboard = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "DATE":
      return { ...state, date: action.value };
      break;
    case "DURATION":
      return { ...state, duration: action.value };
      break;
    case "SECONDS":
      return { ...state, seconds: action.value };
      break;
    case "TIME":
      return { ...state, time: action.value };
      break;
    case "MINUTE":
      return { ...state, minute: action.value };
      break;
    case "PRODUCT_DETAIL":
      return { ...state, itemDetail: action.value };
      break;
    case "AD_NAME":
      return { ...state, adName: action.value };
      break;
    case "TYPE_NAME":
      return { ...state, typeName: action.value };
      break;
    case "ABOUT":
      return { ...state, about: action.value };
      break;
    case "UPDATE_DATE":
      return { ...state, updateDate: action.value };
      break;
    case "UPDATE_SECONDS":
      return { ...state, updateSeconds: action.value };
      break;
    case "UPDATE_DURATION":
      return { ...state, updateDuration: action.value };
      break;
    case "UPDATE_TIME":
      return { ...state, updateTime: action.value };
      break;
    case "UPDATE_MINUTE":
      return { ...state, updateMinute: action.value };
      break;
    case "UPDATE_PRODUCT_DETAIL":
      return { ...state, updateItemDetail: action.value };
      break;
    case "UPDATE_AD_NAME":
      return { ...state, updateAdName: action.value };
      break;
    case "UPDATE_TYPE_NAME":
      return { ...state, updateTypeName: action.value };
      break;
    case "UPDATE_ABOUT":
      return { ...state, updateAbout: action.value };
      break;
    case "FINAL_PRICE":
      return { ...state, finalPrice: action.value };
      break;

    // default: {
    //   throw new Error(`action type : ${action?.type}`);
    // }
  }
};

function SelectBillboardControllerProvider({ children }) {
  let currDate = new Date();
  currDate.setDate(new Date().getDate() + 1);

  const initialState = {
    date: currDate.toISOString().slice(0, 10),
    duration: 30,
    seconds: 30,
    time: "",
    itemDetail: "",
    adName: "",
    typeName: "Marketing/promotional ads",
    about: "",
    minute: 1,

    updateDate: currDate.toISOString().slice(0, 10),
    updateDuration: 0,
    updateTime: "",
    updateSeconds: 30,
    updateItemDetail: "",
    updateAdName: "",
    updateTypeName: "",
    updateAbout: "",
    updateMinute: "",
    finalPrice: 0,
  };
  const [controller, dispatch] = useReducer(reducer, initialState);
  // const provider = useMemo(() => [controller, dispatch], []);
  return (
    <SelectBillboard.Provider value={[controller, dispatch]}>
      {children}
    </SelectBillboard.Provider>
  );
}

// Typechecking props for the selectBillboardControllerProvider
SelectBillboardControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useSelectBillboardController = () => {
  const context = useContext(SelectBillboard);
  if (!context) {
    throw new Error(
      "useSelectBillboardController should be used inside the selectBillboardControllerProvider."
    );
  }
  return context;
};

const setDate = (dispatch, value) => dispatch({ type: "DATE", value });
const setDuration = (dispatch, value) => dispatch({ type: "DURATION", value });
const setSeconds = (dispatch, value) => dispatch({ type: "SECONDS", value });
const setMinute = (dispatch, value) => dispatch({ type: "MINUTE", value });
const setTime = (dispatch, value) => dispatch({ type: "TIME", value });
const setItemDetail = (dispatch, value) =>
  dispatch({ type: "PRODUCT_DETAIL", value });
const setAdName = (dispatch, value) => dispatch({ type: "AD_NAME", value });
const setTypeName = (dispatch, value) => dispatch({ type: "TYPE_NAME", value });
const setAbout = (dispatch, value) => dispatch({ type: "ABOUT", value });

const setUpdateDate = (dispatch, value) =>
  dispatch({ type: "UPDATE_DATE", value });

const setUpdateSeconds = (dispatch, value) =>
  dispatch({ type: "UPDATE_SECONDS", value });

const setUpdateDuration = (dispatch, value) =>
  dispatch({ type: "UPDATE_DURATION", value });
const setUpdateMinute = (dispatch, value) =>
  dispatch({ type: "UPDATE_MINUTE", value });
const setUpdateTime = (dispatch, value) =>
  dispatch({ type: "UPDATE_TIME", value });
const setUpdateItemDetail = (dispatch, value) =>
  dispatch({ type: "UPDATE_PRODUCT_DETAIL", value });
const setUpdateAdName = (dispatch, value) =>
  dispatch({ type: "UPDATE_AD_NAME", value });
const setUpdateTypeName = (dispatch, value) =>
  dispatch({ type: "UPDATE_TYPE_NAME", value });
const setUpdateAbout = (dispatch, value) =>
  dispatch({ type: "UPDATE_ABOUT", value });

const setFinalPrice = (dispatch, value) =>
  dispatch({ type: "FINAL_PRICE", value });

export {
  SelectBillboardControllerProvider,
  useSelectBillboardController,
  setDate,
  setDuration,
  setSeconds,
  setTime,
  setItemDetail,
  setAdName,
  setTypeName,
  setAbout,
  setMinute,
  //
  setUpdateDate,
  setUpdateSeconds,
  setUpdateDuration,
  setUpdateMinute,
  setUpdateTime,
  setUpdateItemDetail,
  setUpdateAdName,
  setUpdateTypeName,
  setUpdateAbout,
  setFinalPrice,
};
